/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/**
 * Includes:
 * Fonts
 * Bootstrap
 * Generics
 */
import "./src/assets/stylesheets/styles.scss";

import React from "react";
import SSRProvider from "react-bootstrap/SSRProvider";

/** Wrap root element. */
export const wrapRootElement = ({ element }) => {
	return <SSRProvider>{element}</SSRProvider>;
};
